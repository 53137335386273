<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('registry.wizard_registry.first_step_title') }}</p>
        <p>{{ $t('registry.wizard_registry.first_step_subtitle') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <registry-form ref="registryForm"/>
      </div>
    </div>
  </div>
</template>

<script>
  import RegistryForm from "../forms/RegistryForm";

  export default {
    name: "NewRegistryWizardFirstStep",
    components: {RegistryForm},
    methods: {
      validate() {
        return new Promise((resolve, reject) => {
          this.$refs.registryForm.getRegistryData()
            .then((success) => {
              if (!success) {
                reject();
              } else {
                resolve(success);
              }
            })
            .catch((err) => {
              reject(err)
            });
        });
      }
    }
  }
</script>

<style scoped>

</style>
