<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('registry.wizard_registry.fourth_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <registry-personal-data :editable="false" :registry="registry"/>
      </div>
      <div class="col-12 col-md-4">
        <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">
              {{ $t('registry.wizard_registry.contact_data') }}
            </h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component
              :label="$t('page.phones')"
              :value="registry.phones.length" />
            <list-group-item-component
              :label="$t('page.emails')"
              :value="registry.emails.length" />
            <list-group-item-component
              :label="$t('page.addresses')"
              :value="registry.addresses.length" />
          </ul>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import RegistryPersonalData from "../components/RegistryPersonalData";
  import Registry from "@/models/registry";
  import ListGroupItemComponent from "@/components/ListGroupItemComponent";

  export default {
    name: "NewRegistryWizardFourthStep",
    components: {ListGroupItemComponent, RegistryPersonalData},
    props: {
      registry: {
        type: Object,
        default: Registry
      }
    }
  }
</script>

<style scoped>

</style>
